.Toastify__toast {
  border-radius: 10px;
  box-shadow: -4px 4px 14px rgb(0 0 0 / 29%);
  font-size: 18px;
  z-index: 9999999;
}
@media (max-width: 600px) {
  .Toastify__toast-theme--light {
    font-size: 14px;
  }
  .Toastify__toast {
    width: 220px;
  }
  .Toastify__toast-container {
    display: flex;
    justify-content: center;
  }
}
