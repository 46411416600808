@import "../../../index.scss";
.video-sec {
  .row {
    @media (max-width: 768px) {
      flex-direction: column;
      > div {
        width: 100%;
        flex: 1;
        max-width: 100%;
      }
    }
  }
  .video-txt {
    h2 {
      color: $widget-title-cl;
      font-size: 30px;
      font-family: $secondary-font;
      margin-bottom: 24px;
    }
    p {
      color: $text-cl;
      font-size: 24px;
      text-align: center;
      line-height: 26px;
      width: 100%;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
    @media (max-width: 768px) {
      text-align: center;
    }
  }

  .video-parent {
    padding: 10px 10px 5px 10px;
    // background-color: #f6f6f6;
    border-radius: 12px;
    box-shadow: 0 0 3px 2px #f3f3f3;
    // @media (max-width:575px) {
    //     padding: 0px;
    // }
    .cont {
      position: relative;
      border-radius: 10px;
      height: 100%;
      .cont-img {
        width: 100%;
        height: 100%;
        overflow: hidden;
        iframe {
          border-radius: 10px;
        }
      }

      .video-title {
        position: absolute;
        bottom: 16px;
        z-index: 1;
        padding-right: 24px;
        h2 {
          color: #fff;
          font-family: $secondary-font;
          font-size: 16px;
        }
      }
    }
  }
}
.modal-backdrop.show {
  opacity: 0.9;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 1;
}

.custom-modal.modal-body {
  width: 100%;
  height: 100%;
}
.custom-modal.modal-body video {
  width: 100%;
  height: 100%;
}
