.error-page-parent {
  .cont {
    background-image: url(../../Assets/Img/404/bg.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    flex-direction: column;
    .cont-txt {
      .widget-title {
        border-bottom: 0;
        margin: 24px 0 32px 0;
        h2 {
          font-size: 21px;
        }
      }
      .btn {
        display: block;
      }
    }
  }
}
