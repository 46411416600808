@import "../../../index.scss";
.categories-parent {
  margin-bottom: 72px;
  .card-parent {
    background-color: #f6f6f6;
    border-radius: 5px;
    padding: 16px;
    height: 100%;
    .cont {
      background-color: #fff;
      padding: 8px 8px 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      .cont-img {
        width: 72px;
        height: 72px;
        margin: 0 auto;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .cont-txt {
        text-align: center;
        h2 {
          font-size: 20px;
          margin-bottom: 24px;
        }

        p {
          font-size: 14px;
          color: #42504d;
          text-align: center;
          @media (min-width: 993px) {
            min-height: 100px;
          }
        }

        .all-btn {
          margin-top: auto;
        }
      }
    }
  }
}
