.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: unset!important;
}



.App {
  overflow-x: hidden;
}


 .banner-sec .slick-slide{
   padding: 0 20px;
 }
 .add_margin{
   border-radius: 20px;
   overflow: hidden;
 }


.news-ticker .slick-track{
  height: auto !important;
}


 
 .recent-books-sec{
   width: 100%;
 }

 .slick-prev{
  display: none !important;
 }

 .slick-next{
   display: none !important;
 }


 .site_message_closed {
  height: 100vh;
}
.site_message_closed .bg1 {
  background-color: hsla(0,0%,100%,.8);
  width: 40%;
  top: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-50%,-50%);
  padding: 34px;
  text-align: center;
  border-radius: 10px;
}
.site_message_closed .bg1 h5 {
  font-size: 25px;
  font-weight: 700;
  color: #00a388;
}