@import '../../../../index.scss';
.add-comment-parent {
    margin: 40px 0;
    background-color: #f6f6f6;
    padding: 32px;
    border-radius: 10px;
    h2 {
        color: $widget-title-cl;
        font-size: 26px;
        font-family: $secondary-font;
    }
    .FormParent {
        label {
            color: $text-cl;
            font-size: 14px;
        }
        textarea {
            border-radius: 10px;
            background-color: #fff;
            min-height: 250px;
        }
        .primary-btn {
            width: auto;
        }
    }
}