@font-face {
  font-family: 'Tajawal';
  src:  url(./Assets/Fonts/Tajawal-Regular.ttf),
        url(./Assets/Fonts/Tajawal-Black.ttf) ,
        url(./Assets/Fonts/Tajawal-Bold.ttf),
        url(./Assets/Fonts/Tajawal-ExtraBold.ttf),
        url(./Assets/Fonts/Tajawal-Light.ttf)
    

}
@font-face {
  font-family: 'Hacen Maghreb Bd';
  src:url(./Assets/Fonts/Hacen-Maghreb-Bd.ttf);
}


/* general variables */
@import './Assets/sass/main-variables.scss';



body {
  margin: 0;
  font-family: 'tajawal', sans-serif;
  direction: rtl;
  text-align: right;
  overflow-x: hidden;
  &::-webkit-scrollbar {
      width: 5px;
  }

  &::-webkit-scrollbar-track { 
      background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
      background:$base-cl;
  }



  &::-webkit-scrollbar-thumb:hover {
      background:$base-light-cl;
  }
}

.scrollable-sec  {
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track { 
      background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
      background:$base-cl;
  }



  &::-webkit-scrollbar-thumb:hover {
      background:$base-light-cl;
  }
}

p,h1,h2,h3,h4,h5,h6 {
  word-break: break-word;
}
h1:after {
  position: absolute;
  content: "";
  -webkit-animation: Dots 2s cubic-bezier(0,.39,1,.68) infinite;
  animation: Dots 2s cubic-bezier(0,.39,1,.68) infinite;
}
@-webkit-keyframes Dots {
  0% {
    content: "";
  }
  33% {
    content: ".";
  }
  66% {
    content: "..";
  }
  100% {
    content: "...";
  }
}
@keyframes Dots {
  0% {
    content: "";
  }
  33% {
    content: ".";
  }
  66% {
    content: "..";
  }
  100% {
    content: "...";
  }
}
a {
  transition: all 0.5s ease-in-out;
}
/* General helper classes */
.custom-switch , .custom-control {
  padding: 0;
}
.flx-row {
  align-items: center;
}
.txt-right {
  text-align: right;
}
.flx-centered {
  display: flex;
  justify-content: center;
  align-items:center;
}
.inline-centerd {
  display: inline-flex;
  justify-content: center;
  align-items:center;
}
.m-l-8{
  margin-left: 8px;
}

.m-r-8 {
  margin-right:8px;
}
.m-b-40 {
  margin-bottom: 40px;
}
.small-btn {
  height: 32px;
}
.lg-btn {
  height: 40px;
}
.xl-btn {
  height: 48px; 
}
.menu-btn-parent {
  display: flex;
  flex-wrap: wrap;
}

.bold {
  font-weight: bold;
}
.primary-btn {
    background: $base-cl;
    color: #fff;
    border-color: $base-cl;
    font-weight: bold;
    &:hover {
      background-color: $base-hover-cl;
      color:$base-cl;
      border-color: $base-cl;
    }
}
.primary-btn-rgba {
  background: $base-hover-cl;
  color: $base-cl;
  border-color: $base-cl;
  font-weight: bold;
  &:hover {
    background-color: $base-cl;
    color:#fff;
    border-color: $base-cl;
  }
}
.red-btn {
    background-color: $light-red-cl;
    border-color: $red-light-cl;
    color:$red-dark-cl;
    font-family: $secondary-font;
    font-size:14px;
    &:hover {
      background-color: $red-dark-cl;
      color:#fff;
      border-color: $red-dark-cl;
    }
}
.all-btn {
  font-family: $secondary-font;
  color: $red-dark-cl;
  background-color: $light-red-cl;
  padding:4px 16px;
  border-radius: 15px;
  border-color:$light-red-cl;
  &:hover {
    background-color: $red-dark-cl;
    color:#fff;
    border-color: $red-dark-cl;
  }
}
.transparent-btn {
  font-family: $secondary-font;
  color:  $base-cl;
  background-color: transparent;
  padding:4px 16px;
  border-radius: 15px;
  border-color:$base-light-cl;
  &:hover {
    background-color: $base-hover-cl;
    color: $base-cl;
    border-color: $base-cl;
  }
  
}
.grey-btn {
  background-color: transparent;
  color: #888;
  border:1px solid#AFAFAF;
  padding:4px 16px;
  border-radius: 5px;
}
.t-show {
  display: none;
}
/* general styles */@at-root
section {
  margin: 24px 0 40px 0;
}
a {
  color: $widget-title-cl;
}
a:hover {
  text-decoration: none;
}
/* sliders */
.slick-slider {
  -webkit-user-select: auto;
  user-select: auto;
}

.slick-dots{
  position: relative;
  bottom: unset;
}

.slick-slide > div:focus,
.slick-slide img:focus {
  outline: 0;
}
.slick-next:hover , .slick-prev:hover ,
.slick-next:focus , .slick-prev:focus {
  background-color:#E6E6E6;
}

.slick-dots li button:before {
  font-size: 10px;
}
.slick-dots li.slick-active button:before {
  opacity: 1;
  font-size:12px;
  color: $base-cl;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
  color: $base-cl;
}
.slick-prev:before, .slick-next:before {
  color:$base-cl
}
.slick-prev:before { 
  content: "‹"; 
}

.slick-arrow:hover:before {
  color: #B3B3B3;
}

.slick-next:before {
    content: "›"; 
}

.slick-prev { 
  left:24px;
  display: flex!important;
  justify-content: center;
  align-items: center;
  background: #E6E6E6;
  text-align: center;
  border-radius: 4px;
  font-size: 0;
  padding: 0;
  top: 35%;
  width: 20px;
  height: 20px;
  z-index: 1;
  &:before {
      align-items: center;
      font-size: 19px;
      left: 7px;
      position: absolute;
      bottom: 5px;
      color:$base-cl;
  }
}
.slick-next {
  left:48px;
  display: flex!important;
  justify-content: center;
  align-items: center;
  background: #E6E6E6;
  text-align: center;
  border-radius: 4px;
  font-size: 0;
  padding: 0;
  top: 35%;
  width: 20px;
  height: 20px;
  &:before {
      align-items: center;
      font-size: 19px;
      left: 7px;
      position: absolute;
      bottom: 5px;
      color:$base-cl;
  }
}
/* breadcrumbs */
.breadcrumb {
  background-color: transparent;
  font-size: 14px;
  color:$text-cl;
}
.breadcrumb-item.active {
  color:$text-cl;
}

.breadcrumb-item+.breadcrumb-item::before {
  padding:  0 8px;
}

.pagination-parent-books {
  margin-right: auto;
}

.alert-sec {
  a {
    color: inherit;
    font-weight: bold;
  }
}

/* custom errors popup styling */
.alret-parent {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0,36,30,0.8);
  z-index: 100052;
  display: flex;
  justify-content: center;
  align-items: center;
  .close-alert {
        width: 100%;
        height: 100%;
        opacity: 0.8;
        position: fixed;
        top: 0;
        left: 0;
  }
  .cont{
    background: #fff;
    border-radius: 10px;
    padding: 24px;
    position: relative;
    width: 30%;
    z-index: 1;
    @media(max-width:992px){
      width: 90%;
    }
    .cont-txt {
        .heading {
          width: 100%;
          padding: 16px;
          border-radius: 10px;
          text-align: center;
          color: $widget-title-cl;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          h2 {
            margin-bottom: 0;
          }
          .alert-icon {
            color: $red-dark-hover-cl;
            &.sucess {
              color: $base-cl;
            }
            font-size: 40px;
            margin-bottom: 16px;
          }
        }
        .errors-parent {
          margin: 16px 0;
          padding: 16px;
          border-radius: 10px;
          min-height: 100px;
          text-align: center;
          
        }
    }

    .controls {
      text-align: center;
        button {
          width: 40%;
        }
    }
  }
}
/* titles */
.widget-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #EFEFEF;
    width: 100%;
    text-align: right;
    margin-bottom: 32px;
    padding-bottom: 16px;

    h2{
      font-size: 20px;
      font-family: $secondary-font;
      line-height: 36px;
      color: $widget-title-cl;
      margin-bottom: 0px;
    }
}
/* pagination */
.pagination-parent {
  list-style: none;
  li{
    margin-left: 4px;
    button {
      width: 32px;
      height: 32px;
      border-radius: 4px;
      border:1px solid $light-gray-bg;
      color: $text-cl;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      &:focus {
        outline: 0;
      }
    }
    &.last-page , &.first-page {
      button {
        background-color: $base-cl;
        border-color: $base-cl;
        color: #fff;

        &.disabled-btn {
          opacity:0.4;
          pointer-events: none;
        }
      }
    }
    &.active {
      button {
        color: $base-cl;
        border-color: $base-cl;
      }
    }
  }
}

.errors-alert {
  height: 55px;
  display: flex;
  align-items: center;
  padding: 0 32px;
  background-color:#FFD9D9;
  border: 1px solid $error-cl;
  border-radius: 5px;
  margin-bottom: 16px;
  color: $error-cl;
  .alert-icon {
    font-size: 25px;
    margin-left: 8px;
  }
  a {
    color: $error-cl;
    font-weight: bold;
  }
}

.sucess-alert {
  height: 55px;
  display: flex;
  align-items: center;
  padding: 0 32px;
  background-color:$base-hover-cl;
  border: 1px solid $base-cl;
  border-radius: 5px;
  color:$base-cl;
  margin-bottom: 16px;
  .alert-icon {
    font-size: 25px;
    margin-left: 8px;
  }
}

.has-error {
  label {
    color: $error-cl!important;
  }
  .error-txt{
    color: $error-cl!important;
    font-size: 12px;
    font-weight: bold;
    margin-top: 8px;

  }
  .form-control:focus , .form-control {
    background-color: #fff;
    border-color: #a30000d4;
    outline: 0;
    box-shadow:0px 0px 5px 0px #a30000d4;
  }

  .custom-input-file-parent {
    background-color: #fff;
    border-color: #a30000d4;
    outline: 0;
    box-shadow:0px 0px 5px 0px #a30000d4;
    border-radius: 5px;
  }
}

.send-message-parent {
  .cont {
    .heading {
      background-color: $base-cl;
      color: #fff;
      height: 84px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      margin-bottom: 16px;
      h2 {
        font-size: 24px;
        font-weight: bold;
        margin: 0;
      }
    }
  }
  .FormParent {
    margin: 0;
  }
}

.no-content-show {
  margin-top: 64px!important;
  margin: 0 auto;
  padding: 48px;
  text-align: center;
  border: 1px solid #f6f6f6;
  border-radius: 16px;
  .cont-txt {
  p {
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 32px;
  }

  a {
      padding: 12px 24px;
      border-radius: 5px;            
      background-color: #FBE2CD;
      &:hover {
          color:#FF9B44;
      }
      .plus-icon {
          margin-left: 8px;
          background: #FF9B44;
          color: #fff;
          border-radius: 50%;
          padding: 3px;
          width: 16px;
          height: 16px;
      }
  }
  }
}

/* forms style */ 
.FormParent { 
  margin: 40px 0;
  .form-label {
      font-family: Tajawal;
      font-weight: bold;
      font-size: 14px;
      line-height: 26px;
      text-align: right;
      color: #142320;
  }
  textarea {
    border-radius: 5px;
    background: #f8f8f8;
    border: 0;
    padding: 16px;
    min-height: 150px;
    &::placeholder{
        color: #a1a1a1;
    }
}
  input {
      border-radius: 5px;
      background: #f8f8f8;
      border: 0;
      padding: 16px;
      &::placeholder{
          color: #a1a1a1;
      }
  }
  select {
    width: 100%;
    height: 48px;
      border-radius: 5px;
      background: #f8f8f8;
      border: 0;
      padding:  0 16px;
      color: #a1a1a1;
      &::placeholder{
          color: #a1a1a1;
      }
      &:focus {
        border: 0;
        outline: none;
      }
  }

  label {
      margin-right: 0;
  }
  .form-check-label {
      margin-right: 20px;
      font-family: Tajawal;
      font-weight: bold;
      font-size: 14px;
      line-height: 26px;
      text-align: right;
      color: #142320;
  }
  .login-btn {
      display: flex;
      width: 100%;
      border-radius: 5px;
      height: 40px;
  }
  .form-text {
      margin-top: 16px;
      text-align: center;
      span {
          color: #142320;
          font-size: 14px;
          line-height: 26px;
      }
      a{
          
          font-weight: 500;
          font-size: 14px;
          line-height: 26px;
          text-align: right;
          color: #00a388;
          margin-right: 5px;
          text-decoration: underline;
      }
      .forgetPasswrod {
          a {
              text-decoration: none;
              margin: 0;
          }
      }
  }
  .primary-btn {
      width: 100%;
  }
  .radio-parent {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .contact-label {
          background-color: $base-cl;
          color: #fff;
          font-size: 16px;
          padding: 2px 16px;
          border-radius: 12px;
          border-bottom-right-radius: 0;

      }
      .radio-label {
          position: relative;
          padding:4px 25px 4px 20px;
          margin-bottom: 12px;
          cursor: pointer;
          font-size: 16px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          display: flex;
          background-color: #E3E5E8;
          color: $text-cl;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          &:not(:last-of-type){
              margin-left: 16px;
          }
          input[type="radio"] {
              position: absolute;
              opacity: 0;
              cursor: pointer;
              &:checked  ~ .checkmark {
                  background-color:$base-cl;
              }
              &:checked ~ .checkmark:after {
                  display: block;
              }
          }
          .checkmark {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 8px;
              height: 12px;
              width: 12px;
              background-color: #eee;
              border-radius: 50%;
              border: 1px solid $base-cl;
              &::after {
                  content: "";
                  position: absolute;
                  display: none;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50% ,-50%);
                  width: 4px;
                  height: 4px;
                  border-radius: 50%;
                  background: #fff;
              }
          }
          &:hover {
              input ~ .checkmark {
                  background-color: #ccc;
              }
          }
      }
  }

  .custom-input-file-parent {
    position: relative;
    input {
        position: relative;
        opacity: 0;
        z-index: 2;
        cursor: pointer;
        appearance: none;
    }
    .custom-input-file {
        position: absolute;
        top: 0;
        width: 100%;
        border-radius: 5px;
        background: #f8f8f8;
        padding: 8px 8px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 1;
        .colored-txt {
            background-color: $base-cl;
            color: #fff;
            padding: 4px 15px;
            font-size: 14px;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            min-width: 95px;
            width: 95px;
        }
        .changable-txt {
            color: #E0E1E7;
            margin-right: 8px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 12px;
        }
    }
}
}


@media (min-width:993px) {
  .desktop-hide {
    display: none;
  }
}

@media (max-width:768px) {
  .m-center {
    text-align:center;
  }
  .t-none{
    display: none!important;
  }
  .t-show {
    display: block;
  }
  .mob-reverse {
    flex-direction: column-reverse;
  }
  .button-search {
    font-size: 0;
    height: 48px;
  }
  .breadcrumb {
    background-color: $base-cl;
    display: flex;
    justify-content: center;
    color: #fff;

    .breadcrumb-item.active {
      color:#fff;
      font-size: 24px;
      line-height: 36px;
      font-family: $secondary-font;
      &::before{
        display: none;
      }
    }
  }
  
} 

@media (max-width:580px) {
  .m-none {
    display: none!important;
  }
  
}