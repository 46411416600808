.banner-sec {
    .errors-alert {
        display: flex!important;
    }
    .banner-parent {
        width: 100%;
        &:focus-visible{
            outline: none;
        }
        cursor:grab;
        img {
            width: 100%;
            margin: auto;
            display: block;
            height: 150px;
            object-fit: cover;
            border-radius: 20px;
            margin-bottom: 15px;
        }
    }
}