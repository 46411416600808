@import '../../../index.scss';
.news-cats-parent {
    margin-bottom: 24px;
    .cats-heading {
        display: inline-flex;
        align-items: center;
        background-color: $base-cl;
        color:#fff;
        border-radius: 5px;
        padding: 16px;
        margin-bottom: 4px ;
        width: 100%;
        h2 {
            cursor: pointer;
            font-size: 14px;
            font-family: $secondary-font;
            margin-bottom: 0;
            line-height: 26px;
        }
        
    }
    .cats-body {
        padding: 24px;
        background-color: $light-gray-bg;
        border-radius: 5px;
        width: 100%;
        @media(max-width: 768px) {
            padding-bottom: 0; 
        }
        
        ul {
            list-style: none;
            padding: 0;
            @media(max-width: 768px) {
                padding: 0; 
                margin-bottom: 0;
            }
            li {
                border-bottom: 1px solid #E8E8E8;
                padding: 8px 8px 16px;
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                &:last-child{
                    border-bottom: 0;
                }

                a , p{
                    cursor: pointer;
                    color: #BCBCBC;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    margin-bottom: 0;
                    &.activeLink {
                        color:#2577B5;
                        span{
                            color: inherit;
                        }
                        .register-icon {
                            transform:rotate(-90deg);
                        }
                    }
                    span {
                        color:$widget-title-cl;
                        font-size: 14px;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}