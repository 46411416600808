@import '../../../index.scss';
.related-blog-sec {
    background-color: #FBFBFB;
    padding: 56px 0;
    .blog-post-parent {
        border-radius: 15px;
        direction: rtl;
        .cont {
            padding: 4px;
            border-radius: 12px;
            background-color:#fff;
            margin: 0 15px;
    
            .cont-img {
                width: 100%;
                height: 100%;
                height: 250px;
                overflow: hidden;
                border-radius: 8px 8px 0 0;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .cont-txt {
                padding: 16px;
                .date {
                    color:$text-cl;
                    font-size: 12px;
                    margin-bottom: 16px;
                    span {
                        margin-right: 8px;
                        
                    }
                }
                h2 {
                    font-size: 20px;
                    font-family: $secondary-font;
                    color:$widget-title-cl;
                    margin-bottom: 8px;
                    a {
                        color:$widget-title-cl;
                    }
                }
                p {
                    color:$text-cl;
                    font-size: 12px;
                    line-height: 24px;
                    margin-bottom: 8px;
                    overflow: hidden;
                }
                .primary-btn {
                    margin: 16px 0;
                }
            }
        }
    }
    .slick-dots {
        margin-top: 24px;
    }
}