@import '../../index.scss';
.payment-info-page {
    .cont {
        border: 1px solid #EAEAEA;
        border-radius: 10px;
        padding: 16px;
    }
    .cont-title {
        background-color: $base-cl;
        color: #fff;
        padding: 24px 16px;
        margin-bottom: 16px;
        border-radius: 10px;
        h2 {
            font-size: 24px;
        }
    }

    .cont-img {
        img {
            width: 100%;
            height: 100%;
        }
    }
    .cont-txt {
        color: $text-cl;
        margin: 16px 0;
        .cont-p {
            margin-left: 16px; 
            &::after {
                content: ':';
                margin: 0 3px;
            }
        }
    }
}