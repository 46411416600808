@import '../../../index.scss';
.news-ticker {
    margin: 24px 0 24px 0;
    background-color:$light-gray-bg;
    border-radius: 5px;
    padding: 14px 24px;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    .slick-slide {
        text-align: right;
    }
    .ticker-body {
        font-size: 14px;
        line-height: 26px;
        text-align: right;
        color: #222121;
        margin-bottom: 0;
    }
    .slick-list {
        order: 1;
        width: 100%;
    }
    button.slick-arrow.slick-prev {
        order: 2;
    }
    button.slick-arrow.slick-next {
        order:3;
    }
    .slick-prev { 
        left:24px;
        transform: rotateZ(90deg);
        display: flex!important;
        justify-content: center;
        align-items: center;
        background: #E6E6E6;
        text-align: center;
        border-radius: 4px;
        font-size: 0;
        padding: 0;
        top: 35%;
        width: 20px;
        height: 20px;
        z-index: 1;
        &:before {
            align-items: center;
            font-size: 19px;
            left: 7px;
            position: absolute;
            bottom: 5px;
            color:#BCBCBC;
        }
    }
    .slick-next {
        left:48px;
        transform: rotateZ(90deg);
        display: flex!important;
        justify-content: center;
        align-items: center;
        background: #E6E6E6;
        text-align: center;
        border-radius: 4px;
        font-size: 0;
        padding: 0;
        top: 35%;
        width: 20px;
        height: 20px;
        &:before {
            align-items: center;
            font-size: 19px;
            left: 7px;
            position: absolute;
            bottom: 5px;
            color:#BCBCBC;
        }
    }
}
