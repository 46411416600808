@import "../../../index.scss";
.events-parent-page {
  .cont {
    padding: 16px;
    border-radius: 10px;
    background-color: #fbfbfb;
    margin: 0;
    margin-bottom: 24px;

    .cont-img {
      width: 100%;
      height: 100%;
      padding: 0;
      height: 250px;
      overflow: hidden;
      border-radius: 8px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
      }
    }
    .cont-txt {
      padding: 24px 0;
      .flx-centered {
        justify-content: flex-start;
        .date {
          color: $text-cl;
          font-size: 12px;
          margin-bottom: 16px;
          span {
            margin-right: 8px;
          }
        }
      }

      h2 {
        font-size: 20px;
        font-family: $secondary-font;
        color: $widget-title-cl;
        margin-bottom: 8px;
        a {
          color: $widget-title-cl;
        }
      }
      .desc {
        color: $text-cl;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 8px;
      }
      .primary-btn {
        margin: 16px 0;
      }
    }
  }
}
