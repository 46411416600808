@import '../../../../index.scss';
.comments-list-parent{
    .heading {
        border-bottom: 1px solid #f6f6f6;
        padding-bottom: 16px;
        margin-bottom: 40px;
        h2 {
            color: $widget-title-cl;
            font-size: 26px;
            font-family: $secondary-font;
            margin-bottom: 0;
        }
    }

    .comment-body {
        border: 1px solid #f6f6f6;
        padding: 16px;
        border-radius: 10px;
        margin-bottom: 24px; 
        .cont {
            background-color: #F6F6F6;
            border-radius: 10px;
            padding: 16px 8px;
            margin-right: 8px;
            align-items: center;
            .cont-img {
                width: 60px;
                height: 60px;
                border: 2px solid #fff;
                border-radius: 50%;
                overflow: hidden;
                margin-left: 8px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .cont-txt {
                a {
                    font-size: 14px;
                    color: $widget-title-cl;
                    margin-bottom: 8px;
                    font-weight: bold;
                }
                .role {
                    background: $base-hover-cl;
                    border: 1px solid $base-cl;
                    color:$base-cl;
                    font-weight: 500;
                    padding: 2px 5px;
                    border-radius: 4px;
                    font-size: 12px;
                    margin-left: 16px;
                }
                .date {
                    color: #222121;
                    font-size: 12px;
                    i {
                        margin-left: 8px;
                    }
                }
            }
        }
        .comment-txt {
            margin-top: 16px;
            color: $text-cl;
            font-size: 18px;
            line-height: 1.7;
        }
    }
}