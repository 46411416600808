@import "../../index.scss";
.pricavy-page-parent {
  .cont {
    border: 1px solid #eaeaea;
    border-radius: 10px;
    padding: 16px;
  }
  .cont-title {
    background-color: $base-cl;
    color: #fff;
    padding: 24px 16px;
    margin-bottom: 16px;
    border-radius: 10px;
    h2 {
      font-size: 24px;
    }
  }

  .cont-img {
    max-height: 300px;
    height: 300px;
    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }
  .cont-txt {
    color: $text-cl;
    margin: 16px 0;
  }
}
