$base-cl: #00a388;
$base-hover-cl: #c8f5ed;
$base-light-cl: #87d4c7;
$red-light-cl: #ffd7cd;
$red-dark-cl: #ff9b44;
$red-dark-hover-cl: #f5552c;
$light-red-cl: #f5a55f61;
$gold-cl: #e9c54e;
$light-gold-cl: #fff0c6;
$dark-gold-cl: #e9c54e;
$light-gray-cl: #8f8e8e;
$light-gray-bg: #f6f6f6;
$widget-title-cl: #142320;
$text-cl: #222121;
$error-cl: #e80000;
$error-shadow: #a30000;

$primary-font: "tajawal", sans-serif;
$secondary-font: "Hacen Maghreb Bd", sans-serif;